import Header from '../components/Header'
import Course from '../components/Course'
import NotesPage from './NotesPage'
import Corporate from './Corporate'
import Modal from './Modal'
import LoginForm from './LoginForm'
import NoMatch from "./NoMatch"
import Page from "./Page"
import ForceLogin from "./ForceLogin"
import ScrollToTop from "./ScrollToTop"

// hooks
import useShowModule from '../hooks/useShowModal'
import useLocalStorage from "../hooks/useLocalStorage"

// styled components
import { Global } from '../styles/Global'
import { LearningPlatformApp } from '../styles/app'
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

/**
 * App component is the top level parent component
 */

function App() {
  // show login modal
  const loginModal = useShowModule()

  // login storage
  const localLogin = useLocalStorage("login", true)

  return (
    <Router>
      <ScrollToTop />
      <Global />
      <LearningPlatformApp className="learning-platform-app">

        {loginModal.showModal &&
          <Modal onClose = { loginModal.setShowModal }>
            <LoginForm
              onLogin = { localLogin.login }
              onClose = { loginModal.setShowModal }
            />
          </Modal>
        }

        <Header
          login = { localLogin.storedValue }
          onLogin = { loginModal.setShowModal }
          onLogout = { localLogin.logout }
        />

        { !localLogin.storedValue &&
          <Switch>
            <Route exact path="/">
              <Corporate
                onLogin = { loginModal.setShowModal }
              />
            </Route>

            <Route exact path="/screentest">
              <Page
                content = "screentest"
                file = "/globalContent.json"
              />
            </Route>

            <Route exact path="/login/:course">
              <ForceLogin
                onLogin = { localLogin.login }
              />
            </Route>

            <Route path="/day">
              <Corporate
                onLogin = { loginModal.setShowModal }
                loginAlert = { true }
              />
            </Route>

            <Route path="/notes">
              <Corporate
                onLogin = { loginModal.setShowModal }
                loginAlert = { true }
              />
            </Route>

            <Route path="/course">
              <Corporate
                onLogin = { loginModal.setShowModal }
                loginAlert = { true }
              />
            </Route>

            <Route path="*">
              <NoMatch />
            </Route>

          </Switch>
        }

        { localLogin.storedValue &&
          <Switch>
            <Route exact path="/">
              <Corporate
                isLoggedIn = { true }
                file = { localLogin.storedValue.file }
              />
            </Route>

            <Route exact path="/login/:course">
              <Redirect to="/" />
            </Route>

            <Route exact path="/course/advanced">
              <Course
                file ="/advanced/content.json"
              />
            </Route>

            <Route path="/day/:link">
              <NotesPage
                type="Day"
              />
            </Route>

            <Route path="/notes/:link">
              <NotesPage
                type="Notes"
              />
            </Route>

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        }

      </LearningPlatformApp>
    </Router>
  )
}

export default App;
