//components
import Notes from './Notes'
import LoginButton from './LoginButton'
import ViewCourseLink from './ViewCourseLink'
import Alert from './Alert'
import { Link } from "react-router-dom"

//styles
import { NotesPageWrapper, NotesPageSpace } from '../styles/notesPage'
import { MailingList } from '../styles/page'
import { Intro, Courses, Services, About } from '../styles/corporate'

// hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

// svg images
import desktop from "../images/desktop.svg"
import teamug from "../images/teamug.svg"

function Corporate(props){
  const mailingList = useFetchPageContent("mailingList", "/globalContent.json")
  const loggedOutMsg = useFetchPageContent("loggedOutAlertMsg", "/globalContent.json")

  return(
    <NotesPageWrapper className="notes-page">
      <NotesPageSpace className="notes-page-space">
        {props.loginAlert &&
          <Alert> {loggedOutMsg.info} </Alert>
        }
        <Intro>
          <div className="illustration">
            <img src={desktop} className="illu-desktop" alt="desktop with abstract python code" />
            <img src={teamug} className="illu-teamug" alt="tea mug" />
          </div>
          <div className="intro-text">
            <Notes>{ "/content/corporate/intro.md" }</Notes>

          <ViewCourseLink
            title="Advanced Python"
            short="Python Course"
            link="/course/advanced"
          />

          </div>
        </Intro>
        <Courses>
          <div className="intro"><Notes>{ "/content/corporate/courses.md" }</Notes></div>
          <MailingList className="mailing-list"><Notes>{ mailingList.info }</Notes></MailingList>
        </Courses>
        <Services>
          <Notes>{ "/content/corporate/services.md" }</Notes>

        </Services>
        <About>
          <Notes>{ "/content/corporate/about.md" }</Notes>
        </About>
      </NotesPageSpace>
    </NotesPageWrapper>
  )
}

export default Corporate
